<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
      <!-- 전체 서버 목록 리스트 -->
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="mr-1 ml-1 mt-1 mb-0">
            <b-row>
              <!-- 타이틀 -->
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix mb-2">
                <div class="float-left"></div>
                <b-breadcrumb class="float-left font-weight-bolder pl-0">
                  <b-breadcrumb-item @click="back()"
                    >전체 서버 목록</b-breadcrumb-item
                  >
                  <b-breadcrumb-item active
                    >전체 서버 목록 상세</b-breadcrumb-item
                  >
                </b-breadcrumb>
                <!-- <h4 class="float-left font-weight-bolder mt-8px">
                  
                  <feather-icon
                    class="cursor-pointer"
                    icon="ArrowLeftIcon"
                    size="22"
                    @click="back()"
                  />
                  전체 서버 목록 상세
                </h4> -->
                <div
                  class="justify-content-center spinner-custom float-right ml-1"
                  :class="{ 'd-none': active }"
                >
                  <b-spinner
                    v-for="variant in variants"
                    :variant="variant"
                    :key="variant"
                  ></b-spinner>
                </div>
                <input
                  type="text"
                  v-model="searchTerm"
                  class="col-md-2 float-right vgt-input"
                  disabled
                  style="background: #f3f3f3"
                />
                <!-- <b-form-select
                    class="col-md-2 float-right"
                    v-model="selectArea"
                    size=""
                    :options="this.areaOption"
                    placeholder="UCS List"
                    @change="setFilter(selectArea)"
                  /> -->
              </b-col>
              <!-- 타이틀 END -->

              <!-- 탭 메뉴 -->
              <!-- <b-col
                cols="12"
                md="12"
              > 
                <b-tabs class="custom">
                  <b-tab title="All" @click="clickTab('all')">
                  </b-tab>
                  <b-tab title="info" @click="clickTab('info')">
                  </b-tab>
                  <b-tab title="Critical" @click="clickTab('critical')">
                  </b-tab>
                  <b-tab title="Major" @click="clickTab('major')">
                  </b-tab>
                  <b-tab title="Minor" @click="clickTab('minor')">
                  </b-tab>
                  <b-tab title="Warning" @click="clickTab('warning')">
                  </b-tab>
                </b-tabs>
              </b-col> -->
              <!-- 검색 박스 / 버튼 -->
            </b-row>
          </div>
          <div class="">
            <b-row class="mr-1 ml-1 justify-content-end">
              <b-col md="2" class=""> </b-col>
            </b-row>
          </div>
          <!-- <div class="postion-relative text-center table-noview table-responsive">
            <table class=""></table>      
          </div> -->
          <div class="pl-1 pr-1">
            <vue-good-table
              :columns="ServObNtColumns"
              :rows="this.items"
              :rtl="direction"
              v-dragscroll="{ target: '.vgt-responsive' }"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
              class="table good-table-custom table-light"
              @on-row-click="onRowClick"
            >
              <div slot="emptystate" class="text-center">
                조회된 목록이 없습니다.
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'sn'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
                </div>
                <!-- <div v-if="props.column.field === 'ip'" class="text-nowrap">
                  {{ props.formattedRow.ip }}
                </div> -->
                <div
                  v-if="props.column.field === 'operState'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.operState }}
                </div>
                <div
                  v-if="props.column.field === 'biosPostState'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.biosPostState }}
                </div>
                <div v-if="props.column.field === 'model'" class="text-nowrap">
                  {{ props.formattedRow.model }}
                </div>
                <div v-if="props.column.field === 'serial'" class="text-nowrap">
                  {{ props.formattedRow.serial }}
                </div>
                <div v-if="props.column.field === 'dn'" class="text-nowrap">
                  {{ props.formattedRow.dn }}
                </div>
                <div
                  v-if="props.column.field === 'assignedToDn'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.assignedToDn }}
                </div>
                <div
                  v-if="props.column.field === 'numOfCores'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.numOfCores }}
                </div>
                <div
                  v-if="props.column.field === 'numOfCoresEnabled'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.numOfCoresEnabled }}
                </div>
                <div
                  v-if="props.column.field === 'numOfThreads'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.numOfThreads }}
                </div>
                <div
                  v-if="props.column.field === 'availableMemory'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.availableMemory }}
                </div>
                <!-- <div
                    v-if="props.column.field === 'setting'"
                    class="text-nowrap"
                    >
                    <a
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.serv-all-detail
                      size="sm"
                      @click="detailProps(items[props.row.originalIndex])"
                      >
                      <button
                      style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                      size="sm"
                      >
                      상세
                      </button>
                    </a>
                  </div> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-2"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <serv-all-detail :detailItems="this.detailItem"></serv-all-detail>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<style>
.badge-critical {
  background-color: #ea5455 !important;
}
.badge-major {
  background-color: #ff9f43 !important;
}
.badge-minor {
  background-color: #ffd500 !important;
}
.badge-warning {
  background-color: #00967e !important;
}
.badge-info {
  background-color: #616161 !important;
}
.breadcrumb-item {
  font-size: 1.286rem;
}
.breadcrumb-item.active {
  font-size: 1.286rem;
  color: #5e5873;
}
</style>
<script>
// import ucsData from '@/assets/api/ucs.ts'
import Ripple from "vue-ripple-directive";

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BButton,
  VBToggle,
  BSpinner,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  BFormInput,
  BFormDatepicker,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";

import store from "@/store/index";
import { dragscroll } from "vue-dragscroll";
import { VueGoodTable } from "vue-good-table";

import ServAllDetail from "./components/ServAllDetail.vue";

const qs = require("qs");

export default {
  directives: {
    Ripple,
    dragscroll,
    "b-toggle": VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    // BAvatar,
    VueGoodTable,
    BFormSelect,
    BButton,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    BFormDatepicker,
    BBreadcrumb,
    BBreadcrumbItem,
    ServAllDetail,
  },
  data() {
    return {
      data: {
        items: [],
        totalInvoices: 0,
      },
      pageLength: 10,
      searchTerm: "",
      variants: ["primary"],
      active: true,
      ucsUrl: "ucsapi/nuova",
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      itemLength: 0,
      selectArea: "",
      areaOption: [{ text: "UCS LIST", value: "" }],
      detailItem: {},
      ServObNtColumns: [
        {
          field: "sn",
          label: "번호",
          sortable: true,
          sortFn: this.sortFn,
          width: "60px",
        },
        {
          field: "ip",
          label: "IP",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "IP",
          },
        },
        {
          field:
            this.$route.query.equipCode === "EQC002"
              ? "operState"
              : "biosPostState",
          label: "Overall Status",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Overall Status",
          },
        },
        {
          field: "model",
          label: "model",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Model",
          },
        },
        {
          field: "serial",
          label: "serial",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Serial",
          },
        },
        {
          field:
            this.$route.query.equipCode === "EQC001" ? "dn" : "assignedToDn",
          label: "profile",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Profile",
          },
        },
        {
          field: "numOfCores",
          label: "cores",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Cores",
          },
        },
        {
          field: "numOfCoresEnabled",
          label: "Cores Enabled",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Cores Enabled",
          },
        },
        {
          field: "numOfThreads",
          label: "Threads",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Threads",
          },
        },
        {
          field: "availableMemory",
          label: "Memory",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Memory",
          },
        },
        // { field: 'setting', label: '상세'},
      ],
      items: [],
      resultList: [],
      totalList: [],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    returnUcsAllServListStus() {
      return this.$store.state.servStus;
    },
  },
  watch: {
    returnUcsAllServList(val) {
      if (val == true) {
        this.items = this.$store.state.servAllList;
      }
    },
  },
  created() {
    // this.getServLog()
    this.areaOption = [{ text: "UCS LIST", value: "" }];
    this.setFilter(this.$route.query.ip);
    if (this.$store.state.ucsServerList.length > 0) {
      for (let i = 0; this.$store.state.ucsServerList.length > i; i++) {
        this.areaOption.push({
          text: this.$store.state.ucsServerList[i].name,
          value: this.$store.state.ucsServerList[i].groupIp,
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.servStus == true) {
      this.items = this.$store.state.servAllList;
    }
  },
  methods: {
    /**
     * 서버 전체 목록 페이지로 이동
     * @create 2021-04-14
     * @author 정한슬
     * @description 서버 전체 목록 페이지로 이동
     */
    back() {
      this.$router.push({ name: "ucs-servall", query: { stus: 1 } });
    },
    /**
     * 받아온 서버 IP로 검색조건 설정
     * @create 2021-04-14
     * @author 정한슬
     * @description 받아온 서버 IP로 검색조건 설정
     */
    setFilter(data) {
      this.searchTerm = data;
      this.$set(this.ServObNtColumns[1], "hidden", true);
      // this.$set(this.ServObNtColumns[1].filterOptions, 'filterValue', data);
    },
    /**
     * 상세 슬라이드 open
     * @create 2021-04-14
     * @author 정한슬
     * @description 상세 슬라이드 open
     */
    onRowClick(data) {
      this.detailItem = data.row;
      this.$root.$emit("bv::toggle::collapse", "serv-all-detail");
    },
  },
};
</script>

<style lang="scss" scoped>
th,
td {
  text-align: center;
  white-space: nowrap;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
